import React from "react"
import styled from "styled-components"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import PageHeader from "../components/pageHeader"
import Opportunity from "../components/wellness/opportunity"
import Icons from "../components/wellness/icons"
import Cards from "../components/wellness/cards"
import { graphql } from "gatsby"
import { withPreview } from "gatsby-source-prismic"

const sectionMapper = {
  header: PageHeader,
  opportunity: Opportunity,
  icons: Icons,
  cards: Cards,
}

const WellnessPage = ({ data: queryData }) => {
  const { uid, data } = queryData.prismicWellnessPage
  const bookData = data.body.find(section => section.slice_type === "book")

  const sections = data.body.filter(section => section.slice_type !== "book")

  return (
    <Layout bookBannerData={bookData ? bookData.primary : undefined}>
      <SEO title={uid} />
      <Container>
        {sections.map((section, i) => {
          const Component = sectionMapper[section.slice_type]
          return (
            <Component key={i} {...section.primary} items={section.items} />
          )
        })}
      </Container>
    </Layout>
  )
}

export default withPreview(WellnessPage)

const Container = styled.div`
  margin: 0 auto;
`

export const query = graphql`
  query {
    prismicWellnessPage {
      _previewable
      uid
      data {
        body {
          ... on PrismicWellnessPageBodyHeader {
            id
            slice_type
            primary {
              title {
                html
              }
              text_right {
                text
              }
              text_left {
                text
              }
              tagline {
                text
              }
            }
          }
          ... on PrismicWellnessPageBodyOpportunity {
            id
            slice_type
            primary {
              title {
                text
              }
              text {
                text
              }
              foreground_image {
                alt
                fluid {
                  aspectRatio
                  sizes
                  src
                  srcSet
                }
              }
              background_image {
                alt
                fluid {
                  aspectRatio
                  sizes
                  src
                  srcSet
                }
              }
            }
          }
          ... on PrismicWellnessPageBodyIcons {
            id
            slice_type
            items {
              icon {
                alt
                fluid {
                  src
                  srcSet
                  aspectRatio
                  sizes
                }
              }
              text {
                text
              }
            }
          }
          ... on PrismicWellnessPageBodyCards {
            id
            slice_type
            items {
              text {
                text
              }
              image {
                alt
                fluid {
                  aspectRatio
                  sizes
                  src
                  srcSet
                }
              }
            }
          }
          ... on PrismicWellnessPageBodyBook {
            id
            slice_type
            primary {
              button_text
              link {
                url
                link_type
              }
              text {
                text
              }
            }
          }
        }
      }
    }
  }
`
