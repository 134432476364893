import React, { useRef, useState } from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import rightArrow from "../../assets/icons/arrowRight.png"
import leftArrow from "../../assets/icons/arrowLeft.png"

const Cards = ({ items }) => {
  const [showLeftArrow, setShowLeftArrow] = useState<boolean>(false)
  const sliderRef = useRef(null)
  const lastCardRef = useRef(null)
  const scrollRight = scrollOffset => {
    setShowLeftArrow(true)
    sliderRef.current.scrollLeft += scrollOffset
  }
  const scrollLeft = scrollOffset => {
    if (sliderRef.current.scrollLeft === 0) {
      setShowLeftArrow(false)
    }
    sliderRef.current.scrollLeft -= scrollOffset
  }
  const amount = items.length
  return (
    <Wrapper>
      <Container ref={sliderRef} col={amount}>
        <LeftArrow
          src={leftArrow}
          alt="left scroll"
          onClick={() => scrollLeft(360)}
          show={showLeftArrow}
        />
        {items.map((card, i) => {
          if (i === amount - 1) {
            return <Card ref={lastCardRef} key={i} {...card} />
          }
          return <Card key={i} {...card} />
        })}
        <RightArrow
          src={rightArrow}
          alt="right scroll"
          onClick={() => scrollRight(360)}
        />
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  padding: 40px 0 120px 0;
`
const Container = styled.div<{ col: number }>`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: ${({ col }) => `repeat(${col}, 350px)`};
  grid-template-rows: minmax(150px, 1fr);
  margin: 0 auto;
  overflow-x: hidden;
  width: 100%;
  scroll-behavior: smooth;
`
const RightArrow = styled.img`
  position: absolute;
  top: 40%;
  right: 24px;
  cursor: pointer;
  z-index: 1;
`
const LeftArrow = styled.img<{ show: boolean }>`
  position: absolute;
  top: 40%;
  left: 24px;
  cursor: pointer;
  z-index: 1;
  display: ${({ show }) => (show ? "initial" : "none")};
`
const Card = card => {
  return (
    <CardContainer>
      <ImageContainer>
        <Img fluid={card.image.fluid} alt={card.image.alt || "wellness card"} />
        <Text>{card.text.text}</Text>
      </ImageContainer>
    </CardContainer>
  )
}

export default Cards

const CardContainer = styled.div`
  max-width: 349px;
`

const ImageContainer = styled.div`
  position: relative;
  .gatsby-image-wrapper {
    height: 450px;
  }
`

const Text = styled.p`
  color: #fff;
  position: absolute;
  bottom: 24px;
  left: 24px;
  text-transform: capitalize;
  font-family: "Moderat Bold", sans-serif;
`
