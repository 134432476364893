import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

const Icons = ({ items }) => {
  return (
    <Container>
      {items.map((icon, i) => (
        <ItemContent key={i}>
          <IconContainer>
            <Img fluid={icon.icon.fluid} />
          </IconContainer>
          <Text key={i}>{icon.text.text}</Text>
        </ItemContent>
      ))}
    </Container>
  )
}

export default Icons

const Container = styled.div`
  padding: 80px 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  grid-gap: 40px;
  justify-content: center;
  width: 80%;
  margin: 0 auto;
`
const ItemContent = styled.div``
const IconContainer = styled.div`
  display: grid;
  align-items: center;
  height: 200px;
  max-width: 80%;
  margin: 0 auto;
  align-self: center;

  @media (max-width: 528px) {
    height: 95% !important;
    width: 95% !important;
    margin: 0 auto;
  }
`
const Text = styled.p`
  text-align: center;
  text-transform: capitalize;
  color: var(--primary-text);
  font-size: 2rem;
  font-family: "Canela Light", sans-serif;
  line-height: 2.5rem;
  margin: 1rem 0;
  padding: 0 8px;
  @media (max-width: 1080px) {
    margin: 0;
  }
`
