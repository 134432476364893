import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { Text } from "../atoms/text"

const Opportunity = ({ title, text, background_image, foreground_image }) => {
  return (
    <Container>
      <BgTextContainer>
        <BackgroundImageContainer>
          <Img fluid={background_image.fluid} />
        </BackgroundImageContainer>
        <TextContainer>
          <Title>{title.text}</Title>
          <Text>{text.text}</Text>
        </TextContainer>
      </BgTextContainer>
      <ForegroundImageContainer>
        <Img fluid={foreground_image.fluid} />
      </ForegroundImageContainer>
    </Container>
  )
}

export default Opportunity

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 80%;
  margin: 0 auto;
  align-items: center;
  padding: 40px 0;
`
const BgTextContainer = styled.div`
  align-self: flex-start;
  @media (max-width: 1076px) {
    align-self: center;
  }
`
const Title = styled.h2`
  font-family: "Moderat", sans-serif;
  color: var(--primary-text);
`

const BackgroundImageContainer = styled.div`
  .gatsby-image-wrapper {
    width: 903px;
    height: 700px;
  }
  padding-right: 10%;
  align-self: flex-start;
  margin-bottom: 2rem;
  @media (max-width: 1076px) {
    align-self: center;
    padding-right: 0;
    .gatsby-image-wrapper {
      width: inherit;
      height: unset;
    }
  }
`

const ForegroundImageContainer = styled.div`
  padding: 20% 0 0 0;
  position: absolute;
  right: 0;
  z-index: 1;
  .gatsby-image-wrapper {
    width: 551px;
    max-height: 700px;
    height: 700px;
  }
  @media (max-width: 1330px) {
    .gatsby-image-wrapper {
      width: 451px;
    }
  }
  @media (max-width: 1200px) {
    .gatsby-image-wrapper {
      height: 600px;
    }
  }
  @media (max-width: 1076px) {
    padding: 0;
    position: unset;

    z-index: unset;
    .gatsby-image-wrapper {
      width: 513px;
      height: unset;
    }
  }
  @media (max-width: 660px) {
    align-self: center;

    .gatsby-image-wrapper {
      width: 350px;
      height: 400px;
    }
  }
`
const TextContainer = styled.div`
  max-width: 513px;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  @media (max-width: 1076px) {
    align-self: center;
    margin-bottom: 2rem;
  }
`
